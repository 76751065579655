<template>
  <div id="popup_create_operator" title="관리자 등록">
    <div id="insertPopContent" style="width:500px">
      <div class="pop-head">
        <h2>관리자 등록</h2>
        <button id="button_create_close" class="btn-x"></button>
      </div>
      <div class="pop-body">
        <validation-observer ref="sampleValidate">
          <form id="insertPopForm">
            <table class="table-style-default table-pop">
              <colgroup>
                <col width="74px" />
                <col width="*" />
              </colgroup>
              <tbody>
                <tr>
                  <th>아이디<i class="star">*</i></th>
                  <td>
                    <validate-form
                      label="아이디"
                      :rules="{
                        required: true,
                        regex: /[0-9A-Za-z\-_@.]{4,100}/,
                        min: 4,
                        max: 100,
                      }"
                      :errorData="{
                        ...errorData,
                        regex: '영문, 숫자 조합으로 입력해 주세요.',
                      }"
                      inputClass="input-24"
                      inputStyle="width:276px"
                      maxlength="100"
                      v-model="userId"
                      name="userId"
                    />
                    <button
                      type="button"
                      class="bt-line24"
                      id="duplicate_check_button"
                    >
                      중복확인
                    </button>
                    <input
                      type="hidden"
                      value="N"
                      id="user_id_duplicate_check"
                    />
                    <p class="aler-t">※ 영문, 숫자, 특수문자 4-100자 이내</p>
                  </td>
                </tr>
                <tr>
                  <th>이름<i class="star">*</i></th>
                  <td>
                    <validate-form
                      label="이름"
                      :rules="{ required: true, max: 100 }"
                      inputClass="input-24 w-100"
                      maxlength="60"
                      v-model="userName"
                      name="userName"
                    />
                  </td>
                </tr>
                <tr>
                  <th>패스워드<i class="star">*</i></th>
                  <td>
                    <validate-form
                      type="password"
                      label="패스워드"
                      :rules="{
                        required: true,
                        regex: /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{9,30}$/,
                        min: 9,
                        max: 30,
                      }"
                      :errorData="{
                        ...errorData,
                        regex:
                          '숫자, 영문 및 특수문자 9자 ~ 30자까지 입력 가능합니다.',
                      }"
                      inputClass="input-24 w-100"
                      maxlength="60"
                      v-model="userPwd"
                      name="userPwd"
                    />
                    <p class="aler-t">※ 영문, 숫자 및 특수문자 9~30자 이내</p>
                  </td>
                </tr>
                <tr>
                  <th>패스워드 확인<i class="star">*</i></th>
                  <td>
                    <validate-form
                      type="password"
                      label="패스워드 확인"
                      :rules="{
                        required: true,
                        confirmed: '패스워드',
                      }"
                      :errorData="{
                        ...errorData,
                        required: '패스워드를 확인해 주세요.',
                      }"
                      inputClass="input-24 w-100"
                      maxlength="60"
                      v-model="pwdConfirm"
                      name="pwdConfirm"
                    />
                  </td>
                </tr>
                <tr>
                  <th>휴대폰<i class="star">*</i></th>
                  <td>
                    <div class="tel-fill-style">
                      <select
                        class="input-24"
                        id="mphon_no1"
                        name="mphon_no1"
                        v-model="mphon_no1"
                      >
                        <option value="010">010</option>
                        <option value="011">011</option>
                        <option value="016">016</option>
                      </select>
                      <!-- <input
                        type="text"
                        id="mphon_no2"
                        name="mphon_no2"
                        class="input-24"
                        maxlength="4"
                        dataType="NUMBER"
                        v-model="mphon_no2"
                      /> -->
                      <validate-form
                        label="mphon_no2"
                        :rules="{ required: true, regex: /^\d{3,4}$/ }"
                        :errorData="{
                          ...errorData,
                          regex: '숫자 3자리 ~ 4자리로 입력해 주세요.',
                          required: '전화번호를 확인해 주세요.',
                        }"
                        inputClass="input-24"
                        maxlength="4"
                        v-model="mphon_no2"
                        name="mphon_no2"
                      />
                      <!-- <input
                        type="text"
                        id="mphon_no3"
                        name="mphon_no3"
                        class="input-24"
                        maxlength="4"
                        dataType="NUMBER"
                        v-model="mphon_no3"
                      /> -->
                      <validate-form
                        label="mphon_no3"
                        :rules="{ required: true, regex: /^\d{3,4}$/ }"
                        :errorData="{
                          ...errorData,
                          regex: '숫자 3자리 ~ 4자리로 입력해 주세요.',
                          required: '전화번호를 확인해 주세요.',
                        }"
                        inputClass="input-24"
                        maxlength="4"
                        v-model="mphon_no3"
                        name="mphon_no3"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>E-mail<i class="star">*</i></th>
                  <td>
                    <validate-form
                      label="이메일 주소"
                      :rules="{ required: true, email: true }"
                      inputClass="input-24 w-100"
                      maxlength="50"
                      v-model="emailAdr"
                      name="emailAdr"
                    />
                  </td>
                </tr>
                <tr>
                  <th>권한<i class="star">*</i></th>
                  <td>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Select"
                      :rules="{ required: true }"
                    >
                      <select
                        class="input-24 w-100"
                        id="user_group"
                        name="user_group"
                        v-model="userGroup"
                      >
                        <option value="10000">총괄시스템관리자</option>
                        <option value="10001">서비스관리자</option>
                        <option value="10002">증적테스트</option>
                        <option value="10003">테스트테스트</option>
                      </select>
                      <label v-if="errors[0]" class="error-tt">{{
                        errors[0]
                      }}</label>
                    </validation-provider>
                  </td>
                </tr>
                <tr>
                  <th>서비스</th>
                  <td>
                    <input
                      type="checkbox"
                      id="user_srvc_chk"
                      name="user_srvc_chk"
                    />
                    서비스 관리자 설정
                    <select
                      class="input-24 w-100 mt-3"
                      id="user_srvc"
                      name="user_srvc"
                    ></select>
                  </td>
                </tr>
                <tr>
                  <th>허용 IP</th>
                  <td>
                    <div class="panel">
                      <div class="panel-top-title">
                        <p class="panel-top-btns">
                          <input
                            type="button"
                            value="추가"
                            id="btnAddNewIpPop"
                            class="bt-line24 cr-orange"
                          />
                        </p>
                      </div>
                      <div class="panel-body" id="ipGridPanelPop">
                        <table id="valid_ipGridPop"></table>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
          <p class="pop-btn-group">
            <input
              type="button"
              value="등록"
              class="bt-line30 cr-orange"
              id="button_create_add"
              @click="submit()"
            />
            <input
              type="button"
              value="취소"
              class="bt-line30"
              id="button_create_cancel"
            />
          </p>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>
import ValidateForm from '@/components/ValidateForm.vue';
export default {
  name: 'ValidateFormPage',
  components: { ValidateForm },
  data() {
    return {
      userId: '',
      userName: '',
      userPwd: '',
      pwdConfirm: '',
      mphon_no1: '010',
      mphon_no2: '',
      mphon_no3: '',
      emailAdr: '',
      userGroup: 10000,
      errorData: {},
      test: '',
    };
  },
  methods: {
    async submit() {
      const result = await this.$refs.sampleValidate.validate();
      if (result) {
        alert('submit');
      }
    },
  },
};
</script>

<style></style>
