<template>
  <v-container>
    <div class="text-h3 mb-10">Dialog</div>
    <v-btn class="mt-5" elevation="2" @click="alert">alert</v-btn>
    <v-btn class="mt-5 ml-3" elevation="2" @click="confirm">confirm</v-btn>
    <v-btn class="mt-5 ml-3" elevation="2" @click="passwordChk">password</v-btn>
    <v-btn class="mt-5 ml-3" elevation="2" @click="editParam">editParam</v-btn>
  </v-container>
</template>

<script>
// TODO: draggalbe 적용하기
export default {
  methods: {
    alert() {
      /**
       * 알림 버튼 callback 적용하고 싶은 경우, then 추가
       * res.ok : true(확인) false(취소)
       */
      this.$dialogs.alert('테스트 알림입니다.').then(res => {
        this.$dialogs.alert(res);
      });
    },
    confirm() {
      this.$dialogs.confirm('확인버튼을 누르시겠습니까?').then(({ ok }) => {
        if (ok) {
          this.$dialogs.alert('확인');
        } else {
          this.$dialogs.alert('취소');
        }
      });
    },
    passwordChk() {
      this.$dialogs.password().then(({ ok }) => {
        console.log(ok);
      });
    },
    editParam() {
      this.$dialogs.editParam().then(({ ok }) => {
        console.log(ok);
      });
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped></style>
