<template>
  <v-main>
    <div></div>
    <dialog-page />
    <v-banner class="my-5" single-line></v-banner>
    <sample-table-page />
    <v-banner class="my-5" single-line></v-banner>
    <h2>Validate Form</h2>
    <validate-form-page />
  </v-main>
</template>

<script>
import DialogPage from './DialogPage.vue';
import mixins from '@/mixins/CommonMixins';
import SampleTablePage from './SampleTablePage.vue';
import ValidateFormPage from './ValidateFormPage.vue';

export default {
  mixins: [mixins],
  components: {
    DialogPage,
    SampleTablePage,
    ValidateFormPage,
  },
};
</script>
<style lang="scss" scoped></style>
